import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
function loadPage (pagePath) {
  return () => import(`@/${pagePath}`);
}
Vue.use(Meta)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/kampanyalar',
    name: 'Kampanyalar',
    component: loadPage('views/Kampanyalar.vue')
  },
  {
    path: '/biten-kampanyalar',
    name: 'Biten_kampanyalar',
    component: loadPage('views/Biten_kampanyalar.vue')
  },
  {
    path: '/duyurular',
    name: 'Duyurular',
    component: loadPage('views/Duyurular.vue')
  },
  {
    path: '/sikca-sorulan-sorular',
    name: 'Sikca-sorulan-sorular',
    component: loadPage('views/Sikca_sorulan.vue')
  },
  {
    path: '/sikca-sorulan-sorular/nasil-kazanirim',
    name: 'Nasil-Kazanirim',
    component: loadPage('views/Nasil_Kazanirim.vue')
  },
  {
    path: '/kvkk/form',
    name: 'KvkkForm',
    component: loadPage('views/kvkk/KvkkBasvuru.vue')
  },
  {
    path: '/kvkk',
    name: 'Kvkk',
    component: loadPage('views/kvkk/Kvkk.vue')
  },
  {
    path: '/kampanyalar/cek-kazan-dan-vespa-piaggio-gts125-cekilis-bileti-hediye',
    name: 'Vespa-Piaggio',
    component: loadPage('views/Campaigns/Vespa_piaggio.vue')
  },
  {
    path: '/kampanyalar/:id',
    name: 'Campaign_details',
    component: loadPage('views/Campaign_details.vue')
  },
  {
    path: '/klink/:id',
    name: 'Campaign_details3',
    component: loadPage('views/klink.vue')
  },
  {
    path: '/campaign/:id',
    name: 'Campaign_details2',
    component: loadPage('views/Campaign_details.vue')
  },
  {
    path: '/biten-kampanyalar/:id',
    name: 'Finished_campaign_details',
    component: loadPage('views/Finished_campaign_details.vue')
  },
  {
    path: '/kazanmaya-basla',
    name: 'Kazanmaya-basla',
    component: loadPage('views/Kazanmaya.vue')
  },
  {
    path: '/duyurular/jumbo_cekilis',
    name: 'jumbo_cekilis',
    component: loadPage('views/duyurular/CK_jumbocekilis.vue')
  },
  {
    path: '/duyurular/elidor_brezilya_bakim',
    name: 'elidor_brezilya_bakim',
    component: loadPage('views/duyurular/Ck_elidor_brezilya_bakim.vue')
  },
  {
    path: '/duyurular/signalrosmancekilis',
    name: 'signalrosmancekilis',
    component: loadPage('views/duyurular/CK_signalrosmancekilis.vue')
  },
  {
    path: '/duyurular/ck_duru_sabun_jel',
    name: 'ck_duru_sabun_jel',
    component: loadPage('views/duyurular/Ck_duru_sabun_jel.vue')
  },
  {  
    path: '/duyurular/cumhuriyet_yuzuncu_yil',
    name: 'cumhuriyet_yuzuncu_yil',
    component: loadPage('views/duyurular/YuzuncuYil.vue')
  },  
  {
    path: '/duyurular/unilever_ozen_market',
    name: 'ck_onur_market_iphone.vue',
    component: loadPage('views/duyurular/Ck_onur_market_iphone.vue')
  },
  {
    path: '/duyurular/unilever_yunus_market',
    name: 'ck_yunus_market_iphone.vue',
    component: loadPage('views/duyurular/Ck_yunus_market_iphone.vue')
  },
  {
    path: '/duyurular/loreal-kiehls',
    name: 'loreal-kiehls',
    component: loadPage('views/duyurular/Loreal_kiehls.vue')
  },
  {
    path: '/faydali-linkler',
    name: 'faydalı-linkler',
    component: loadPage('views/Links.vue')
  },
  {
    path: '/duyurular/cek-kazandan-500-kisiye-cesitli-oduller-kampanyalari-aciklandi',
    name: 'Cek',
    component: loadPage('views/duyurular/Cek.vue')
  },
  {
    path: '/duyurular/maybelline-iphone-13-xiaomi-elektrikli-scooter-cekilis-sonuclari',
    name: 'Maybelline',
    component: loadPage('views/duyurular/Maybelline.vue')
  },
  {
    path: '/duyurular/500-kisiye-yemek-ceki-kampanyasi-kazananlari-aciklandi',
    name: 'Yemek_ceki',
    component: loadPage('views/duyurular/Yemek_ceki.vue')
  },
  {
    path: '/duyurular/loreal-paris-cilt-iphone-12-xiaomi-mi-robot-vacuum-mop-cekilis-sonuclari-aciklandi',
    name: 'Loreal',
    component: loadPage('views/duyurular/Loreal.vue')
  },
  {
    path: '/duyurular/sodexo-30tl-cekilis-sonuclari',
    name: 'Sodexo',
    component: loadPage('views/duyurular/Sodexo.vue')
  },
  {
    path: '/duyurular/loreal-cosmetica-cekilis-sonuclari-belli-oldu',
    name: 'Garnier',
    component: loadPage('views/duyurular/Garnier.vue')
  },
  {
    path: '/duyurular/loreal-rossmann-cekilisi-kazananlari-belli-oldu',
    name: 'Rossmann',
    component: loadPage('views/duyurular/Rossmann.vue')
  },
  {
    path: '/duyurular/bepanthol-rossmann-cekilisi-kazananlari-belli-oldu',
    name: 'Bepanthol',
    component: loadPage('views/duyurular/Bepanthol.vue')
  },
  {
    path: '/duyurular/bepanthol-eve-cekilisi-kazananlari-belli-oldu',
    name: 'Bepanthol_eve',
    component: loadPage('views/duyurular/Bepanthol_eve.vue')
  },
  {
    path: '/duyurular/yesil-bir-gelecek-icin-dogaya-5000-tohum-attik',
    name: 'Tohum',
    component: loadPage('views/duyurular/Tohum.vue')
  },
  {
    path: '/duyurular/kelloggs-cekilisinin-kazananlari-belli-oldu',
    name: 'Kelloggs',
    component: loadPage('views/duyurular/Kelloggs.vue')
  },
  {
    path: '/duyurular/rexona-men-watsons-cekilisinin-kazananlari-belli-oldu',
    name: 'Rexona',
    component: loadPage('views/duyurular/Rexona.vue')
  },
  {
    path: '/duyurular/watsonstan-k-beauty-hediye-kutusu-kazananlar-belli-oldu',
    name: 'Watsonstan',
    component: loadPage('views/duyurular/Watsonstan.vue')
  },
  {
    path: '/duyurular/loreal-cekilis-kampanyasinda-kazanan-isimler-belli-oldu',
    name: 'Loreal_cekilis',
    component: loadPage('views/duyurular/Loreal_cekilis.vue')
  },
  {
    path: '/duyurular/unilever-cekilis-kampanyasi-kazanan-isimler-belli-oldu',
    name: 'Unilever',
    component: loadPage('views/duyurular/Unilever.vue')
  },
  {
    path: '/land/akaryakit',
    name: 'Akaryakit',
    component: loadPage('views/ads/Akaryakit.vue')
  },
  {
    path: '/land/endise-yok',
    name: 'Endise',
    component: loadPage('views/ads/endise.vue')
  },
  {
    path: '/land/hayat',
    name: 'Enflasyon',
    component: loadPage('views/ads/Enf.vue')
  },
  {
    path: '/land/ev',
    name: 'Ev',
    component: loadPage('views/ads/Ev.vue')
  },
  {
    path: '/land/ogrenci',
    name: 'Ogrenci',
    component: loadPage('views/ads/Ogrenci.vue')
  },
  {
    path: '/k/CK1524',
    name: 'CK1524',
    component: loadPage('views/adtrack/CK1524.vue')
  },
  {
    path: '/k/1538',
    name: 'CK1538',
    component: loadPage('views/adtrack/CK1538.vue')
  },
  {
    path: '/duyurular/cinetech-cekilis-kampanyasi-kazanan-isimler-belli-oldu',
    name: 'Cinetech',
    component: loadPage('views/duyurular/Cinetech.vue')
  },
  {
    path: '/duyurular/cinetech-cekilis-kampanyasi-odul-degisikligi-duyurusu',
    name: 'Cinetech_odul',
    component: loadPage('views/duyurular/Cinetech_odul.vue')
  },
  {
    path: '/duyurular/ogx-watsons-kampanyasi-cekilis-sonuclari',
    name: 'Ogx',
    component: loadPage('views/duyurular/Ogx.vue')
  },
  {
    path: '/geoLimit',
    name: 'geo',
    component: loadPage('views/GeoLimit.vue')
  },
  {
    path: '/firma-gizlilik-politikasi',
    name: 'gizlilik',
    component: loadPage('views/politikalarimiz/Firma_gizlilik.vue')
  },
  {
    path: '/kullanim-kosullari',
    name: 'kullanim',
    component: loadPage('views/politikalarimiz/Kullanim_kosullari.vue')
  },
  {
    path: '/acik-riza-metni',
    name: 'acik',
    component: loadPage('views/politikalarimiz/Acik_riza.vue')
  },
  {
    path: '/uye-aydinlatma-metni',
    name: 'uye-aydinlatma',
    component: loadPage('views/politikalarimiz/Uye_aydinlatma.vue')
  },
  {
    path: '/duyurular/iletisim-tercihleri/',
    name: 'tesekkurler',
    component: loadPage('views/duyurular/Tesekkurler.vue')
  },
  {
    path: '/404',
    name: '404',
    component: loadPage('views/404.vue')
  },
  {
    path: '/eMagFairy/Regulament',
    name: 'regulament',
    component: loadPage('views/FileViewer.vue'),
    props: { link: '/files/eMagFairyRegulament.pdf' }
  },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/404.vue')
  },
  {
    path: '/kampanyalar/:id',
    name: 'noncampaign',
    component: loadPage('views/404.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const campaignId = parseInt(to.params.id);
      if (isNaN(campaignId)) {
        next({ name: 'NotFound' });
      } else {
        next();
      }
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
